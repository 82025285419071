import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
//Images
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const PrivateParking = () => {
    return (
        <Layout>
            <Seo
                title="Home Bike Parking - BIKESAFE"
                description="Efficiently organize your bicycles and make the most of unused space. Our home storage bike racks protect your bike, your walls, and your vehicles. Whether you are looking for bike storage for your house, garage, shed, or garden we have a solution. Make the most of your floor space, wall space, with one of our bike stands/racks/lockers."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Home Bike Parking
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <h5>Store your bikes efficiently!</h5>
                        <p className="flow-text">
                            Efficiently organize your bicycles and make the most of unused space. Our home storage bike racks protect your bike, your walls, and your vehicles. Whether you are looking for bike storage for your house, garage, shed, or garden we have a solution. Make the most of your floor space, wall space, with one of our bike stands/racks/lockers.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred" src="../images/sheffield.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">"We were really happy the friendly and efficient service Bike Safe provided. They installed a Sheffield stand in our front garden within days of us contacting them. The stand looks great and provides a convenient place to secure our bikes without cluttering up the hallway."
                                <strong> Emlyn Lamburn, Home Owner, London.</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default PrivateParking;